import { FormGroup } from '@angular/forms';

export function dcwFieldsValidators(actionOnThisFG = true, emailField = 'email', phoneField = 'phone') {
  return (formGroup: FormGroup): { [key: string]: string } | null => {
    const action = actionOnThisFG ? formGroup.get('actions')?.value : formGroup?.parent?.parent?.get('patient')?.get('actions').value;
    const disableDelete = formGroup.get('disabled')?.value;
    const emailControl = formGroup.get(emailField);
    const phoneControl = formGroup.get(phoneField);
    if (disableDelete) {
      return null;
    } else {
      if (action === 'email') {
        phoneControl.setErrors(null);
        if (!emailControl?.value) {
          emailControl.setErrors({ message: 'Field is required' });
          return null;
        } else {
          emailControl.setErrors(null);
          return null;
        }
      }
      if (action === 'phone') {
        emailControl.setErrors(null);
        if (!phoneControl?.value) {
          phoneControl.setErrors({ message: 'Field is required' });
          return null;
        } else {
          phoneControl.setErrors(null);
          return null;
        }
      }
    }
  };
}
